import { Button, TextField } from '@material-ui/core';
import { useStaticQuery, graphql } from 'gatsby';
import React, { useContext, useState } from 'react';
import Head from '../components/head';
import BasicLayout from '../layouts/basic_layout';
import BreadcrumbsLayout from '../layouts/breadcrumbs_layout';
import styles from './index.module.scss';
import stylesBejelentkezes from './bejelentkezes.module.scss';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { REQUEST_PASSWORD_VIA_UNDIDENTIFIED_ACTOR } from '../apollo/mutations/user';
import { SystemStatusContext } from '../providers/SystemStatusProvider';

const ForgottenPassword = () => {
  const { maintenance } = useContext(SystemStatusContext);

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { title: { eq: "Elfelejtett jelszó" } }) {
        frontmatter {
          title
          mainpage
          login
          send
        }
        html
      }
    }
  `);

  const { enqueueSnackbar } = useSnackbar();

  const [sendEmail] = useMutation(REQUEST_PASSWORD_VIA_UNDIDENTIFIED_ACTOR, {
    onCompleted() {
      enqueueSnackbar('Jelszó változtatási kérelem elküldve!', {
        variant: 'success',
      });
    },
    onError(error) {
      enqueueSnackbar('Jelszó változtatási kérelem elküldése sikertelen!', {
        variant: 'error',
      });
    },
    update(cache) {
      cache.evict({ id: 'ROOT_QUERY' });
      cache.gc();
    },
  });

  const onEmailChange = e => {
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    const emailSchema = yup
      .string()
      .email('A megadott email cím nem megfelelő formátumú');

    try {
      emailSchema.validateSync(email);

      setError('');

      sendEmail({
        variables: {
          data: {
            args_0: email,
          },
        },
      });
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <BasicLayout>
      <Head title={data.markdownRemark.frontmatter.title} />
      <BreadcrumbsLayout
        menuItems={[
          { name: data.markdownRemark.frontmatter.mainpage, path: '/' },
          {
            name: data.markdownRemark.frontmatter.login,
            path: '/bejelentkezes',
          },
          { name: 'Elfelejtett jelszó' },
        ]}
      >
        <div className={styles.textContentContainer}>
          <h3>Elfelejtett jelszó</h3>

          <div className={stylesBejelentkezes.container}>
            <label htmlFor="email">E-mail cím</label>
            <TextField
              id="email"
              name="email"
              value={email}
              onChange={onEmailChange}
              variant="outlined"
              fullWidth
            />
            {error && <div style={{ color: 'red' }}>{error}</div>}
            <div className={stylesBejelentkezes.submitButtonContainer}>
              <Button
                className={stylesBejelentkezes.submitButton}
                // disabled={
                //   credentials.username.trim() === '' ||
                //   credentials.password.trim() === ''
                // }
                disabled={maintenance}
                onClick={handleSubmit}
              >
                {data.markdownRemark.frontmatter.send}
              </Button>
            </div>
          </div>
        </div>
      </BreadcrumbsLayout>
    </BasicLayout>
  );
};

export default ForgottenPassword;
